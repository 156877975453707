import React, {useContext, useEffect, useState} from "react";
import "./Invitations.css";
import {Resolution} from "../../logic/FetchResolver";
import {Button, Icon, TextInput} from "@spg/component_library";
import {FormattedMessage, useIntl} from "react-intl";
import {Invitation} from "../../logic/APITypes";
import API from "../../logic/API";
import {SnackbarContext} from "../../utility/SnackbarProvider";
import {Table} from "../../utility/Table";
import {Link} from "react-router-dom";
import chevron from "../../resources/images/chevron.svg";

/**
 * A page listing existing invitations to the SCH and an input to send new invitations
 */
function Invitations() {
  const {addSnackbar} = useContext(SnackbarContext);
  const intl = useIntl();

  const [email, setEmail] = useState("");
  const [emailHadFocus, setEmailHadFocus] = useState(false);
  const [invitations, setInvitations] = useState<Resolution<Invitation[]>>({
    success: false,
    unresolved: true,
  });
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const isEmailInvited = !!(
    invitations.payload &&
    invitations.payload.some((invitation) => invitation.email === email.trim())
  );
  const emailParts = email.split("@");
  const isEmailFormatCorrect =
    emailParts.length > 1 &&
    emailParts[0].length > 0 &&
    emailParts[emailParts.length - 1].length > 0;

  useEffect(() => {
    API.getInvitations().then((response) => setInvitations(response));
  }, []);

  return (
    <div className="invitations page__grid">
      <h1 className="invitations__title" id="invitationsTitle">
        <Link to="/" className="invitations__back">
          <Icon
            href={chevron + "#chevronLeft"}
            title={intl.formatMessage({id: "invitations.actions.back"})}
            strokeColor="#101223"
          />
        </Link>
        <FormattedMessage id="invitations.titles.page" />
      </h1>
      <div className="invitations__info">
        <FormattedMessage
          id="invitations.paragraphs.info"
          values={{
            br: () => <br />,
          }}
        />
      </div>
      <div className="invitations__input">
        <TextInput
          label={intl.formatMessage({id: "invitations.inputs.email"})}
          id="invitationsEmailInput"
          disabled={waitingForResponse}
          value={email}
          onChange={(v) => setEmail(v.target.value)}
          type="email"
          isError={isEmailInvited || (emailHadFocus && !isEmailFormatCorrect)}
          supportingText={
            isEmailInvited
              ? intl.formatMessage({
                  id: "invitations.messages.alreadyInvited",
                })
              : emailHadFocus && !isEmailFormatCorrect
                ? intl.formatMessage({
                    id: "invitations.messages.formatIncorrect",
                  })
                : undefined
          }
          onBlur={() => {
            setEmailHadFocus(true);
          }}
        />
        <div className="invitations__submit">
          <Button
            label={intl.formatMessage({id: "invitations.actions.submit"})}
            configuration={"tertiary"}
            disabled={
              waitingForResponse || !isEmailFormatCorrect || isEmailInvited
            }
            onClick={() => {
              setWaitingForResponse(true);
              API.createInvitation(email.trim()).then((response) => {
                setWaitingForResponse(false);
                setEmailHadFocus(false);
                setInvitations((oldInvitations) =>
                  oldInvitations.payload && response.payload
                    ? {
                        ...oldInvitations,
                        payload: [
                          ...oldInvitations.payload,
                          {...response.payload, email},
                        ],
                      }
                    : oldInvitations,
                );
                if (response.success) {
                  setEmail("");
                  addSnackbar({
                    variant: "timeout",
                    text: intl.formatMessage({
                      id: "invitations.messages.invitationSucceeded",
                    }),
                    timeout: 7000,
                  });
                } else
                  addSnackbar({
                    variant: "close",
                    text: intl.formatMessage({
                      id:
                        "invitations.messages." +
                        (response.httpCode === 409
                          ? "invitationReused"
                          : response.httpCode === 422
                            ? "invitationInvalid"
                            : "invitationFailed"),
                    }),
                    hasCloseButton: true,
                  });
                if (response.httpCode === 409)
                  API.getInvitations().then((response) =>
                    setInvitations((oldInvitations) =>
                      response.success ? response : oldInvitations,
                    ),
                  );
              });
            }}
          />
        </div>
      </div>
      <div className="invitations__list">
        {(invitations.payload && invitations.payload.length > 0) ||
        invitations.unresolved ? (
          <Table
            id={"invitationsTable"}
            isLoading={invitations.unresolved}
            data={(invitations.payload || []).map((invitation) => ({
              id: invitation.id,
              cells: {
                email: invitation.email,
                expiration: {
                  sortingKey: "" + invitation.expiration_date,
                  value: intl.formatDate(
                    new Date(invitation.expiration_date * 1000),
                  ),
                },
              },
            }))}
            columns={[
              {
                key: "email",
                label: intl.formatMessage({
                  id: "invitations.titles.table.email",
                }),
                sortable: true,
              },
              {
                key: "expiration",
                label: intl.formatMessage({
                  id: "invitations.titles.table.expiration",
                }),
                sortable: true,
              },
            ]}
          />
        ) : (
          <div className="invitations__list invitations__list--blank">
            <FormattedMessage
              id={
                "invitations.messages.invitationsTable" +
                (invitations.success ? "Blank" : "Failed")
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Invitations;
