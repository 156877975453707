import {TileApplications} from "@spg/component_library";
import fetchResolver, {Resolution} from "./FetchResolver";
import {Invitation, TermsAndConditions} from "./APITypes";

/** Provides an interface to operations in the backend */
const API = {
  /** Attempts to get all application tiles */
  getApplications: async (): Promise<Resolution<TileApplications>> => {
    return fetchResolver.apiCall({path: "apps"});
  },

  /**
   * Attempts to get an access token for requests concerning invitations.
   * @function
   */
  getInvitationsAccessToken: async (): Promise<Resolution<{token: string}>> => {
    return fetchResolver.resolveFetch<{token: string}>(
      fetchResolver.fetchWithHeaders({
        path: "invitations_access_token",
      }),
    );
  },

  /** Attempts to get all previous, unexpired invitations */
  getInvitations: async (): Promise<Resolution<Invitation[]>> => {
    return fetchResolver.apiCallWithAccessToken(
      {
        path: "invitations",
        method: "GET",
        backend: "keycloak_sidecar",
      },
      {
        endpoint: API.getInvitationsAccessToken,
        headerName: "Read-Invitations-Access-Token",
      },
    );
  },

  /**
   * Attempts to invite a new user to the SCH
   * @param email - the email that the invitation will be sent to
   */
  createInvitation: async (
    email: string,
  ): Promise<Resolution<Omit<Invitation, "email">>> => {
    return fetchResolver.apiCallWithAccessToken(
      {
        path: "invitations",
        method: "POST",
        body: {email},
        backend: "keycloak_sidecar",
      },
      {
        endpoint: API.getInvitationsAccessToken,
        headerName: "Read-Invitations-Access-Token",
      },
    );
  },

  /**
   * Attempts to get an access token for requests concerning terms and conditions.
   * @function
   */
  getTermsAccessToken: async (): Promise<Resolution<{token: string}>> => {
    return fetchResolver.resolveFetch<{token: string}>(
      fetchResolver.fetchWithHeaders({
        path: "tc_access_token",
      }),
    );
  },
  /** Attempts to export the GDPR data */
  exportGDPR: async (): Promise<Resolution<null>> => {
    const date = new Date().toISOString().split("T")[0];
    const filename = `smartcityhub_account_data_${date}.json`;
    return fetchResolver.downloadFile(
      {path: "gdpr/user/export", backend: "keycloak_sidecar"},
      filename,
    );
  },
  /** Attempts to get the current privacy policy/terms and conditions */
  getTerms: async (): Promise<Resolution<TermsAndConditions>> => {
    return fetchResolver.apiCall({
      path: "gdpr/tc",
      suppressCredentials: true,
      backend: "keycloak_sidecar",
    });
  },
  /**
   * Attempts to upload a new privacy policy/terms and conditions
   * @param terms - the new policy to publish
   */
  createTerms: async (terms: string): Promise<Resolution<null>> => {
    return fetchResolver.apiCallWithAccessToken(
      {
        path: "gdpr/tc",
        method: "POST",
        body: {terms},
        backend: "keycloak_sidecar",
      },
      {
        endpoint: API.getTermsAccessToken,
        headerName: "Terms-And-Conditions-Access-Token",
      },
    );
  },
};

export default API;
