import React from "react";
import "./SearchBar.css";
import search from "../resources/images/search.svg";
import {FormattedMessage, useIntl} from "react-intl";

type SearchBarProps = {
  /** A prefix for a unique HTML-ID */
  idBase: string;
  /** The placeholder that is displayed when no value is entered */
  placeholder: string;
};

/**
 * A search bar to look for specific applications and activities
 */
function SearchBar(props: SearchBarProps) {
  return (
    <div className="search-bar">
      <div className="search-bar__row">
        <svg
          width={24}
          height={24}
          viewBox={`0 0 24 24`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href={search + "#globalG"} className="search-bar__icon" />
        </svg>
        <label htmlFor={props.idBase + "SearchBar"} hidden>
          <FormattedMessage id={props.placeholder} />
        </label>
        <input
          id={props.idBase + "SearchBar"}
          type="text"
          placeholder={useIntl().formatMessage({id: props.placeholder})}
        />
      </div>
      <div className="search-bar__underline" />
    </div>
  );
}

export default SearchBar;
