import React, {useContext, useEffect, useState} from "react";
import "./AuthErrorPage.css";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@spg/component_library";
import loginBackground from "../../resources/images/portalBackground.jpg";
import {KeycloakContext} from "../../utility/KeycloakProvider";

type AuthErrorPageProps = {
  /** Why the user is not allowed to use the data market */
  reason: "unauthenticated" | "unauthorized";
  /** Function to call when displaying the info screen (after the flicker timeout) */
  afterTimeout: () => void;
};

/** A screen informing the user about issues with authentication or missing roles. */
function AuthErrorPage(props: AuthErrorPageProps) {
  const {keycloak} = useContext(KeycloakContext);
  const intl = useIntl();

  const [reduceFlicker, setReduceFlicker] = useState(true);

  // Doesn't render anything for one second to give keycloak time to initialize
  useEffect(() => {
    const timeout = setTimeout(() => {
      props.afterTimeout();
      setReduceFlicker(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  if (reduceFlicker) return <></>;

  return (
    <div className="authErrorPage">
      <div className="authErrorPage__contents">
        <h1 className="authErrorPage__title">
          <FormattedMessage id="authErrorPage.titles.page" />
        </h1>
        <div className="authErrorPage__message">
          <p>
            <FormattedMessage id={"authErrorPage.messages." + props.reason} />
          </p>
          <div>
            {props.reason === "unauthenticated" ? (
              <Button
                label={intl.formatMessage({id: "authErrorPage.actions.login"})}
                onClick={() =>
                  keycloak.login({
                    redirectUri: location.href,
                  })
                }
                configuration={"primary"}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="authErrorPage__background"
        style={{backgroundImage: `url(${loginBackground})`}}
      />
    </div>
  );
}

export default AuthErrorPage;
