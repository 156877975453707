import React, {useContext} from "react";
import "./Table.css";
import {useIntl} from "react-intl";
import {AuxiliaryToolTip, Table, TableRow} from "@spg/component_library";
import {ScreenSize} from "./ScreenSize";
import {Link} from "react-router-dom";

type EnrichedRow = Omit<TableRow, "cells"> & {
  /* Dictionary mapping column keys to the cell content in that row */
  cells: Record<
    string,
    | string
    | number
    | {
        sortingKey: string;
        value: string | number | JSX.Element;
        cellClasses?: string;
      }
    | {
        /* The cell text for different default behaviors/presentations */
        content: string;
        /* Whether an overflow activated tooltip should appear */
        hasToolTip?: boolean;
        /* Turns the cell into a basic button */
        onClick?: () => void;
        /* Turns the cell into a link – shouldn't be combined with onClick */
        link?: React.ComponentProps<typeof Link>;
        /* An icon with standard dimensions (24x24) to insert after the content and outside of the tooltip/button */
        additionalIcon?: JSX.Element;
        /* Any custom classes to add to the cell (or cell-container) for additional styling options */
        cellClasses?: string;
      }
  >;
};

/**
 * Fills i18n properties of the Table from the component library with default values and adds some common functionalities
 */
function TableWithIntl(
  props: Omit<
    React.ComponentProps<typeof Table>,
    | "ariaHeaderSortInfo"
    | "ariaHeaderExpandInfo"
    | "expandButtonTitle"
    | "ariaLabelMenuPagination"
    | "iconButtonTitlePrevious"
    | "iconButtonTitleNext"
    | "ariaLabelPageSelectionGenerator"
    | "screenSizeCategory"
    | "data"
  > & {
    data: EnrichedRow[];
  },
) {
  const intl = useIntl();
  const screenSizeCategory = useContext(ScreenSize).category;

  const enrichRow = (row: EnrichedRow, index: number) => {
    const pageSize = props.pageSize || 12;
    const entryCount = props.data.length;
    const inLowerHalf =
      index + 1 > entryCount - (entryCount % pageSize) / 2 ||
      (index <= entryCount - (entryCount % pageSize) &&
        index % pageSize >= pageSize / 2);

    const enrichedCells: TableRow["cells"] = {};
    for (const column in row.cells) {
      const cell = row.cells[column];
      if (typeof cell === "object" && "content" in cell) {
        const cellClasses = cell.cellClasses || "";
        if (!cell.hasToolTip && !cell.onClick && !cell.link)
          enrichedCells[column] = enrichedCells[column] = {
            sortingKey: cell.content,
            value: (
              <div
                className={
                  "enrichedTable__cell enrichedTable__cell--content " +
                  cellClasses
                }
              >
                {cell.content}
              </div>
            ),
          };
        else if (!cell.hasToolTip && cell.onClick)
          enrichedCells[column] = {
            sortingKey: cell.content,
            value: (
              <button
                className={
                  "enrichedTable__cell enrichedTable__cell--button " +
                  cellClasses
                }
                onClick={cell.onClick}
              >
                {cell.content}
              </button>
            ),
          };
        else if (!cell.hasToolTip && cell.link)
          enrichedCells[column] = {
            sortingKey: cell.content,
            value: (
              <Link
                className={
                  "enrichedTable__cell enrichedTable__cell--link " + cellClasses
                }
                {...cell.link}
              >
                {cell.content}
              </Link>
            ),
          };
        else if (!cell.onClick && !cell.link)
          enrichedCells[column] = {
            sortingKey: cell.content,
            value: (
              <div
                className={`enrichedTable__cellContainer ${cellClasses} enrichedTable__cellContainer--${column}${
                  cell.additionalIcon ? " withIcon" : ""
                }`}
              >
                <AuxiliaryToolTip
                  toolTip={cell.content}
                  idBase={"enrichedTableCell" + column + index}
                  tooltipPosition={inLowerHalf ? "top" : "bottom"}
                  toggleMessage={intl.formatMessage({
                    id: "auxiliaryToolTip.actions.toggle",
                  })}
                  overflowActivated
                >
                  <div className="enrichedTable__cell enrichedTable__cell--text">
                    {cell.content}
                  </div>
                </AuxiliaryToolTip>
                {cell.additionalIcon}
              </div>
            ),
          };
        else if (cell.onClick)
          enrichedCells[column] = {
            sortingKey: cell.content,
            value: (
              <div
                className={`enrichedTable__cellContainer ${cellClasses} enrichedTable__cellContainer--${column}${
                  cell.additionalIcon ? " withIcon" : ""
                }`}
              >
                <AuxiliaryToolTip
                  toolTip={cell.content}
                  idBase={"enrichedTableCell" + column + index}
                  tooltipPosition={inLowerHalf ? "top" : "bottom"}
                  overflowActivated
                >
                  <button
                    className="enrichedTable__cell enrichedTable__cell--button"
                    onClick={cell.onClick}
                  >
                    {cell.content}
                  </button>
                </AuxiliaryToolTip>
                {cell.additionalIcon}
              </div>
            ),
          };
        else if (cell.link)
          enrichedCells[column] = {
            sortingKey: cell.content,
            value: (
              <div
                className={`enrichedTable__cellContainer ${cellClasses} enrichedTable__cellContainer--${column}${
                  cell.additionalIcon ? " withIcon" : ""
                }`}
              >
                <AuxiliaryToolTip
                  toolTip={cell.content}
                  idBase={"enrichedTableCell" + column + index}
                  tooltipPosition={inLowerHalf ? "top" : "bottom"}
                  overflowActivated
                >
                  <Link
                    id={`${props.id}${row.id}_link`}
                    className="enrichedTable__cell enrichedTable__cell--link"
                    {...cell.link}
                  >
                    {cell.content}
                  </Link>
                </AuxiliaryToolTip>
                {cell.additionalIcon}
              </div>
            ),
          };
      } else enrichedCells[column] = cell;
    }
    return {...row, cells: enrichedCells};
  };

  return (
    <div className="enrichedTable">
      <Table
        {...props}
        data={props.data.map((row, index) => enrichRow(row, index))}
        screenSizeCategory={screenSizeCategory}
        ariaHeaderSortInfo={intl.formatMessage({
          id: "table.titles.caption",
        })}
        ariaHeaderExpandInfo={intl.formatMessage({
          id: "table.titles.expandHeader",
        })}
        expandButtonTitle={intl.formatMessage({
          id: "table.actions.expandButton",
        })}
        ariaLabelMenuPagination={intl.formatMessage({
          id: "table.titles.pagination",
        })}
        iconButtonTitlePrevious={intl.formatMessage({
          id: "table.pagination.actions.previous",
        })}
        iconButtonTitleNext={intl.formatMessage({
          id: "table.pagination.actions.next",
        })}
        ariaLabelPageSelectionGenerator={(pageNumber) =>
          intl.formatMessage(
            {
              id: "table.pagination.actions.number",
            },
            {pageNumber},
          )
        }
      />
    </div>
  );
}

export {TableWithIntl as Table, type EnrichedRow as EnrichedTableRow};
