import React from "react";
import {FormattedMessage} from "react-intl";
import news1 from "../../resources/images/news1.jpg";
import news2 from "../../resources/images/news2.jpg";
import news3 from "../../resources/images/news3.jpg";

/**
 * A list of dummy news.
 */
function Activities() {
  const dummyNews = [
    {
      title: "Baumampel mit neuen Features",
      description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
      url: "/",
    },
    {
      title: "Neue Keycloakversion",
      description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
      url: "/",
    },
    {
      title: "Heimatforum bekommt neues Erscheinungsbild",
      description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
      url: "/",
    },
  ];

  return (
    <div className="home-activities">
      <div className="home-activities__title">
        <FormattedMessage id="home.titles.activities" />
      </div>
      <div className="home-activities__list">
        {dummyNews.map((entry, i) => (
          <a className="home-activities__news" href={entry.url} key={i}>
            <div className="home-activities__news-image">
              <img src={[news1, news2, news3][i]} alt=""></img>
            </div>
            <div className="home-activities__news-title">{entry.title}</div>
            <div className="home-activities__news-caption">
              {entry.description}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Activities;
