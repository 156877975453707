import React, {useContext} from "react";
import "./Imprint.css";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@spg/component_library";
import {KeycloakContext} from "../../utility/KeycloakProvider";

/**
 * A minimally styled view with the imprint for the Smart City Hub. Currently not localized (german only)
 */
function Imprint() {
  const {keycloak} = useContext(KeycloakContext);
  const intl = useIntl();

  return (
    <div className="imprint">
      <div className="imprint__contents">
        <h1 className="imprint__title imprint__title--page-titel">
          <FormattedMessage id="imprint.titles.page" />
        </h1>
        <p className="imprint__paragraph">
          <FormattedMessage id="imprint.paragraphs.intro" />
        </p>
        {new Array(5).fill(0).map((_, i) => (
          <React.Fragment key={i}>
            <h2 className="imprint__title imprint__title--with-divider">
              <FormattedMessage id={`imprint.titles.section${i + 1}`} />
            </h2>
            <p className="imprint__paragraph">
              <FormattedMessage
                id={`imprint.paragraphs.section${i + 1}`}
                values={{
                  br: <br />,
                  link: (content) => (
                    <a href={"http://" + content}>{content}</a>
                  ),
                  mail: (content) => (
                    <a href={"mailto:" + content}>{content}</a>
                  ),
                }}
              />
            </p>
          </React.Fragment>
        ))}
        <div className="imprint__link">
          <Button
            label={intl.formatMessage({id: "imprint.actions.login"})}
            onClick={() =>
              keycloak.login({
                redirectUri: location.href.replace("/imprint", "/"),
              })
            }
            configuration={"primary"}
          />
        </div>
      </div>
    </div>
  );
}

export default Imprint;
