import React, {useContext, useEffect, useState} from "react";
import "./Terms.css";
import API from "../../../logic/API";
import {TermsAndConditions} from "../../../logic/APITypes";
import {KeycloakContext} from "../../../utility/KeycloakProvider";
import {Resolution} from "../../../logic/FetchResolver";
import {
  Button,
  IconButton,
  LoadingSkeleton,
  ModalContext,
  TextArea,
} from "@spg/component_library";
import {FormattedMessage, useIntl} from "react-intl";
import {SnackbarContext} from "../../../utility/SnackbarProvider";
import edit from "../../../resources/images/edit.svg";

/**
 * A page showing the current privacy (even when not logged in) and an option to allow SCH Administration to upload a new version
 */
function Terms() {
  const {keycloak, initialized, authenticated} = useContext(KeycloakContext);
  const {addSnackbar} = useContext(SnackbarContext);
  const {showModal, hideModal} = useContext(ModalContext);
  const intl = useIntl();

  const [isEditing, setIsEditing] = useState(false);
  const [terms, setTerms] = useState<Resolution<TermsAndConditions>>({
    success: false,
    unresolved: true,
  });
  const [newTerms, setNewTerms] = useState<string>("");
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const uploadTerms = () => {
    setWaitingForResponse(true);
    API.createTerms(newTerms).then((response) => {
      setWaitingForResponse(false);
      if (response.success) {
        addSnackbar({
          text: intl.formatMessage({
            id: "terms.messages.newTermsSucceeded",
          }),
          variant: "timeout",
          timeout: 7000,
        });
        setTerms({
          success: true,
          payload: {text: newTerms, valid_from: Date.now() / 1000},
        });
        setNewTerms("");
        setIsEditing(false);
      } else {
        addSnackbar({
          text: intl.formatMessage({
            id: "terms.messages.newTermsFailed",
          }),
          variant: "close",
          hasCloseButton: true,
        });
      }
    });
  };

  useEffect(() => {
    API.getTerms().then((response) => setTerms(response));
  }, []);

  return (
    <div className="terms page__grid">
      <h1 className="terms__title" id="termsTitle">
        <FormattedMessage id="terms.titles.page" />
        {initialized &&
          authenticated &&
          keycloak.hasResourceRole("SCH Administration") &&
          !isEditing && (
            <span className="terms__edit">
              <IconButton
                idBase={"termsEdit"}
                onClick={() => setIsEditing(true)}
                iconProps={{
                  href: edit + "#globalG",
                  title: intl.formatMessage({id: "terms.actions.edit"}),
                  strokeColor: "#101223",
                }}
              />
            </span>
          )}
      </h1>
      {terms.payload && (
        <span className="terms__title terms__title--valid-from">
          <FormattedMessage
            id="terms.titles.page.validFrom"
            values={{
              date: intl.formatDate(new Date(terms.payload.valid_from * 1000)),
            }}
          />
        </span>
      )}
      {isEditing && (
        <>
          <div className="terms__info">
            <FormattedMessage
              id="terms.paragraphs.info"
              values={{
                br: () => <br />,
              }}
            />
          </div>
          <div className="terms__input">
            <TextArea
              label={intl.formatMessage({id: "terms.inputs.newTerms"})}
              placeholder={intl.formatMessage({
                id: "terms.inputs.newTerms.placeholder",
              })}
              id="termsNewTermsInput"
              disabled={waitingForResponse}
              value={newTerms}
              onChange={(v) => setNewTerms(v.target.value)}
              required
              isResizeable
              maxLength={100000}
            />
            <Button
              label={intl.formatMessage({id: "terms.actions.newTerms"})}
              configuration={"primary"}
              disabled={waitingForResponse || newTerms.trim().length === 0}
              onClick={() => {
                showModal({
                  idBase: "termsNewTerms",
                  headline: intl.formatMessage({id: "terms.titles.modal"}),
                  body: intl.formatMessage({id: "terms.paragraphs.modal"}),
                  primaryButton: {
                    name: intl.formatMessage({
                      id: "terms.actions.modal.confirm",
                    }),
                    onClick: () => {
                      uploadTerms();
                    },
                  },
                  secondaryButton: {
                    name: intl.formatMessage({
                      id: "terms.actions.modal.cancel",
                    }),
                    onClick: () => {
                      hideModal();
                    },
                  },
                });
              }}
            />
          </div>
        </>
      )}
      <div
        className={`terms__text${!terms.success ? " terms__text--error" : ""}`}
      >
        {terms.unresolved ? (
          <div className="terms__loading">
            <LoadingSkeleton width="50%" height="24px" />
            <LoadingSkeleton width="100%" height="200px" />
            <br />
            <LoadingSkeleton width="33%" height="24px" />
            <LoadingSkeleton width="100%" height="200px" />
          </div>
        ) : !terms.payload ? (
          <FormattedMessage id="terms.messages.textFailed" />
        ) : (
          terms.payload.text.split("\n").map((line, i) =>
            line.startsWith("#") ? (
              <h2 key={i} className="terms__title terms__title--h2">
                {line.substring(1).trim()}
              </h2>
            ) : (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ),
          )
        )}
      </div>
    </div>
  );
}

export default Terms;
