import {ErrorReason} from "./APITypes";
import keycloak from "./Keycloak";

/** Relevant REST-Methods */
type Method = "POST" | "GET" | "PATCH" | "PUT" | "DELETE";

/** Possible external backends */
type Backend = "keycloak_sidecar";

/** API-requests return a promise resolving to this type */
type Resolution<T> = {
  /** Whether the fetch was executed and successful */
  success: boolean;
  /** Missing if fetching hasn't finished */
  httpCode?: number;
  /** Whether the request hasn't been made or resolved yet */
  unresolved?: boolean;
  /** Missing if successful or unresolved */
  errorReason?: ErrorReason[] | string;
  /** Missing if unsuccessful or unresolved */
  payload?: T;
};

/** Options to provide when performing API-calls via fetchResolver */
type FetchParameters = {
  /** Relative path to the resource */
  path: string;
  /** HTTP-Method to use, defaults to GET */
  method?: Method;
  /** The keycloak authorization header is added by default but can be removed using this */
  suppressCredentials?: boolean;
  /** An optional body to attach to the fetch */
  body?: object;
  /** Controller to handle aborting of requests */
  abortController?: AbortController;
  /** Specification of an external backend */
  backend?: Backend;
  /** Additional headers that should be added to the request */
  additionalHeaders?: {
    name: string;
    value: string;
  }[];
  /** Whether to request the results translated for the browser locale */
  includeLocale?: boolean;
};

const externalBackends: Record<Backend, string> =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
    ? {
        keycloak_sidecar: "http://localhost:8006/api/",
      }
    : {
        keycloak_sidecar: "/api/",
      };

/** Provides utility functions to standardize how requests to different backends are performed */
const fetchResolver = {
  url:
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
      ? "http://localhost:8000/portal/api/"
      : "/portal/api/",
  /**
   * Fetches a resource using the appropriate headers and info
   * @param fetchParameters The `path` and optionally a `body`, `abortController`, `additionalHeaders`, whether to `suppressCredentials` and non-standard `method` (`GET`) or `backend` (`/api/`)
   * @returns A promise that resolves to the response of the fetch request.
   */
  fetchWithHeaders: ({
    path,
    method,
    suppressCredentials,
    body,
    abortController,
    backend,
    additionalHeaders,
    includeLocale,
  }: FetchParameters) => {
    const header: HeadersInit = new Headers();
    header.set("Content-Type", "application/json");
    if (!suppressCredentials)
      header.set("Authorization", "Bearer " + keycloak.token);
    if (additionalHeaders)
      for (const h of additionalHeaders) header.set(h.name, h.value);
    const request: RequestInit = {
      method: method,
      headers: header,
      credentials: "same-origin",
      signal: abortController?.signal,
    };
    if (body) request.body = JSON.stringify(body);
    const url = new URL(
      (backend ? externalBackends[backend] : fetchResolver.url) +
        encodeURI(path),
      location.origin,
    );
    if (includeLocale) {
      const enIndex = navigator.languages.findIndex((locale) =>
        locale.startsWith("en"),
      );
      const deIndex = navigator.languages.findIndex((locale) =>
        locale.startsWith("de"),
      );
      url.searchParams.append(
        "language",
        deIndex != -1 && deIndex < enIndex ? "de" : "en",
      );
    }
    return fetch(url, request);
  },
  /**
   * Evaluates whether a fetch succeeds
   * @param request The promise to evaluate as produced by a fetch-call
   * @param bodyAsSpecifics Whether to return the contents of the specifics-field
   * @returns A non-rejecting promise
   */
  resolveFetch: async <T>(
    request: Promise<Response>,
    bodyAsSpecifics?: boolean,
  ): Promise<Resolution<T>> => {
    try {
      const response = await request;
      if (response.ok) {
        const len = response.headers.get("content-length");
        if (!len || +len == 0)
          return {success: true, httpCode: response.status};
        try {
          const json = await response.json();
          if (
            bodyAsSpecifics &&
            typeof json === "object" &&
            json &&
            "specifics" in json
          )
            return {
              success: true,
              httpCode: response.status,
              payload: {...json, specifics: undefined, ...json["specifics"]},
            };
          return {
            success: true,
            httpCode: response.status,
            payload: json,
          };
        } catch (errorReason: unknown) {
          return {
            success: false,
            httpCode: response.status,
            errorReason:
              errorReason instanceof Error
                ? errorReason.message
                : String(errorReason),
          };
        }
      }
      const json = (await response.json()) as unknown;
      if (typeof json === "object" && json && "detail" in json) {
        if (
          typeof json.detail === "object" &&
          json.detail &&
          Array.isArray(json.detail) &&
          (json.detail as unknown[]).every(
            (d) => typeof d === "object" && d && "msg" in d,
          )
        )
          return {
            success: false,
            httpCode: response.status,
            errorReason: json.detail as ErrorReason[],
          };
        else
          return {
            success: false,
            httpCode: response.status,
            errorReason: json.detail as string,
          };
      } else
        return {
          success: false,
          httpCode: response.status,
          errorReason: response.statusText,
        };
    } catch (errorReason: unknown) {
      return {
        success: false,
        httpCode: 400,
        errorReason:
          errorReason instanceof Error
            ? errorReason.message
            : String(errorReason),
      };
    }
  },
  /**
   * Fetches an endpoint and wraps the response into a standardized object
   * @param fetchParameters The `path` and optionally a `body`, `abortController`, `additionalHeaders`, whether to `suppressCredentials` and non-standard `method` (`GET`) or `backend` (`/api/`)
   * @returns A non-rejecting promise, resolving to a `Resolution`, indicating whether the request was a `success`, its `httpCode` and potentially an `errorReason` or `payload`
   */
  apiCall: async <T>(
    fetchParameters: FetchParameters,
  ): Promise<Resolution<T>> => {
    return fetchResolver.resolveFetch<T>(
      fetchResolver.fetchWithHeaders(fetchParameters),
    );
  },
  /**
   * Fetches a file from the given endpoint and triggers a download.
   * @param fetchParameters The parameters for the API request.
   * @param filename A filename if the response header doesn't specify one.
   * @returns A Resolution object indicating success or failure.
   */
  downloadFile: async (
    fetchParameters: FetchParameters,
    filename: string,
  ): Promise<Resolution<null>> => {
    try {
      const response = await fetchResolver.fetchWithHeaders(fetchParameters);

      if (!response.ok) {
        return {
          success: false,
          httpCode: response.status,
          errorReason: response.statusText,
        };
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Extract filename from Content-Disposition header (if available)
      const contentDisposition = response.headers.get("Content-Disposition");
      if (contentDisposition) {
        // Accepts both filename="file.jpg" and filename=file.jpg
        const match = contentDisposition.match(/filename="?(.+?)"?(?:;|$)/);
        if (match) {
          filename = match[1];
        }
      }

      // Trigger file download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      return {success: true, httpCode: response.status};
    } catch (error) {
      return {
        success: false,
        httpCode: 400,
        errorReason: error instanceof Error ? error.message : String(error),
      };
    }
  },

  /**
   * Fetches an access token first and then makes the actual API request with it.
   * @param fetchParameters The parameters for the actual API call.
   * @param tokenOptions The parameters for the access token request.
   * @param tokenOptions.endpoint - A function that retrieves the access token.
   * @param tokenOptions.headerName - The HTTP header where the token should be included.
   * @returns A `Promise` resolving to a `Resolution<T>`, containing the API response or an error.
   */
  apiCallWithAccessToken: async <T>(
    fetchParameters: FetchParameters,
    tokenOptions: {
      endpoint: () => Promise<Resolution<{token: string}>>;
      headerName: string;
    },
  ): Promise<Resolution<T>> => {
    const tokenResponse = await tokenOptions.endpoint();

    if (!tokenResponse.success || !tokenResponse.payload?.token) {
      return {
        success: false,
        httpCode: tokenResponse.httpCode || 401,
        errorReason: tokenResponse.errorReason || "Failed to get access token",
      };
    }

    return fetchResolver.resolveFetch<T>(
      fetchResolver.fetchWithHeaders({
        ...fetchParameters,
        additionalHeaders: [
          ...(fetchParameters.additionalHeaders || []),
          {
            name: tokenOptions.headerName,
            value: tokenResponse.payload.token,
          },
        ],
      }),
    );
  },
};

export default fetchResolver;
export {type Resolution};
