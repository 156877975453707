import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import treelights from "../../resources/images/treelights.png";
import nextcloud from "../../resources/images/nextcloud.png";
import datamarket from "../../resources/images/datamarket.png";
import mail from "../../resources/images/mail.svg";
import "./Home.css";
import {Resolution} from "../../logic/FetchResolver";
import {
  ApplicationButton,
  Icon,
  TileApplications,
} from "@spg/component_library";
import SearchBar from "../../reusables/SearchBar";
import {useOutletContext} from "react-router-dom";
import Activities from "./Activities";
import {KeycloakContext} from "../../utility/KeycloakProvider";
import {Link} from "react-router-dom";

/**
 * A page with a list of links to other applications.
 */
function Home() {
  const {keycloak} = useContext(KeycloakContext);
  const {applications} = useOutletContext<{
    applications: Resolution<TileApplications>;
  }>();

  return (
    <div className="home page__grid">
      <h1 className="home__title" id="homeTitle">
        <FormattedMessage id="home.titles.page" />
      </h1>
      <div className="home__search">
        <SearchBar idBase="home" placeholder="home.inputs.search" />
      </div>
      <div className="home__applications">
        {applications.success &&
          applications.payload &&
          Object.keys(applications.payload).length > 0 &&
          Object.values(applications.payload).map((s, index) => {
            if (s.name !== "Smart City Hub Würzburg") {
              return (
                <React.Fragment key={s.id}>
                  <ApplicationButton
                    idBase={s.name.toLowerCase() + "Home"}
                    href={s.url}
                    image={[treelights, nextcloud, datamarket][index % 3]}
                    caption={s.name}
                    size="big"
                  />
                </React.Fragment>
              );
            }
          })}
      </div>
      {keycloak.hasResourceRole("SCH Administration") && (
        <Link className="home__invitations" to={"/invitations"}>
          <Icon href={mail + "#globalG"} title={""} />
          <FormattedMessage id="home.actions.invitations" />
        </Link>
      )}
      <div className="home__activities">
        <Activities />
      </div>
    </div>
  );
}

export default Home;
