import {Snackbar, SnackbarContext} from "@spg/component_library";
import React, {ReactElement, useContext} from "react";
import {useIntl} from "react-intl";

/**
 * Fills i18n property of the SnackbarContext from the component library with a default value and enforces props more strictly
 */
const EnrichedSnackbarContext = React.createContext<EnrichedSnackbarContext>({
  addSnackbar: () => undefined,
  removeSnackbar: () => undefined,
  removeAllSnackbars: () => undefined,
});

type TimeoutSnackbar = Omit<
  React.ComponentProps<typeof Snackbar>,
  "hasCloseButton" | "closeButtonTitle" | "action" | "id"
> & {timeout: number; variant: "timeout"};

type CloseSnackbar = Omit<
  React.ComponentProps<typeof Snackbar>,
  "timeout" | "id"
> & {
  hasCloseButton: boolean;
  variant: "close";
};

type ActionSnackbar = Omit<
  React.ComponentProps<typeof Snackbar>,
  "hasCloseButton" | "closeButtonTitle" | "timeout" | "id"
> & {
  action: React.ComponentProps<typeof Snackbar>["action"];
  variant: "action";
};

type EnrichedSnackbarProps = TimeoutSnackbar | CloseSnackbar | ActionSnackbar;

type EnrichedSnackbarContext = {
  addSnackbar: (snackbarProps: EnrichedSnackbarProps) => void;
  removeSnackbar: (id: string) => void;
  removeAllSnackbars: () => void;
};

function EnrichedSnackbarProvider({children}: {children: ReactElement}) {
  const intl = useIntl();
  const context = useContext(SnackbarContext);

  const addSnackbar = (snackbarProps: EnrichedSnackbarProps) => {
    if (
      "hasCloseButton" in snackbarProps &&
      !("closeButtonTitle" in snackbarProps)
    )
      snackbarProps.closeButtonTitle = intl.formatMessage({
        id: "snackbar.dismiss",
      });
    context.addSnackbar(snackbarProps);
  };

  const removeSnackbar = (id: string) => {
    context.removeSnackbar(id);
  };

  const removeAllSnackbars = () => {
    context.removeAllSnackbars();
  };

  return (
    <EnrichedSnackbarContext.Provider
      value={{addSnackbar, removeSnackbar, removeAllSnackbars}}
    >
      {children}
    </EnrichedSnackbarContext.Provider>
  );
}
export {EnrichedSnackbarProvider, EnrichedSnackbarContext as SnackbarContext};
