import React, {useContext, useEffect, useState} from "react";
import "./App.css";
import {Outlet} from "react-router";
import {NavLink} from "react-router-dom";
import {KeycloakContext} from "./utility/KeycloakProvider";
import {
  Button,
  GlobalMenu,
  ModalContext,
  ModalProvider,
  TileApplications,
  User,
} from "@spg/component_library";
import {FormattedMessage, useIntl} from "react-intl";
import API from "./logic/API";
import {Resolution} from "./logic/FetchResolver";
import AuthErrorPage from "./pages/unprotectedPages/AuthErrorPage";
import {SnackbarContext} from "./utility/SnackbarProvider";

/**
 * A view to navigate to other applications or different pages
 */
function App() {
  const {keycloak, initialized, authenticated} = useContext(KeycloakContext);
  const intl = useIntl();
  const modalContext = useContext(ModalContext);
  const snackbarContext = useContext(SnackbarContext);

  const [applications, setApplications] = useState<
    Resolution<TileApplications>
  >({
    success: false,
    unresolved: true,
  });
  const [userInfo, setUserInfo] = useState<User>({
    firstName: "–",
    lastName: " ",
    email: "–",
  });

  useEffect(() => {
    if (keycloak.idTokenParsed)
      setUserInfo({
        firstName: keycloak.idTokenParsed["given_name"],
        lastName: keycloak.idTokenParsed["family_name"],
        email: keycloak.idTokenParsed["email"],
      });
  }, [initialized]);

  // Fills globalMenu with app list
  useEffect(() => {
    if (authenticated)
      API.getApplications().then((result) => setApplications(result));
  }, [authenticated]);

  const removeOverlaidMessages = () => {
    snackbarContext.removeAllSnackbars();
    modalContext.hideModal();
  };

  if (!initialized || !authenticated)
    return (
      <AuthErrorPage
        reason="unauthenticated"
        afterTimeout={removeOverlaidMessages}
      />
    );

  if (
    !keycloak.hasResourceRole("SCH Mitglied") &&
    !keycloak.hasResourceRole("SCH Administration")
  )
    return (
      <AuthErrorPage
        reason="unauthorized"
        afterTimeout={removeOverlaidMessages}
      />
    );

  return (
    <div className="app">
      <a className="app__skip sr-only" href={location.pathname + "#main"}>
        <FormattedMessage id="app.actions.skipToContent" />
      </a>
      <GlobalMenu
        isLoggedIn={authenticated}
        firstName={userInfo.firstName}
        lastName={userInfo.lastName}
        email={userInfo.email}
        applications={applications.payload}
        altTextApplications={intl.formatMessage({
          id: "globalMenu.applications.actions.open",
        })}
        emptyMessage={intl.formatMessage({
          id: applications.unresolved
            ? "globalMenu.applications.messages.loading"
            : "globalMenu.applications.messages.empty",
        })}
        ariaLabelUserImage={intl.formatMessage({
          id: "globalMenu.userWidget.actions.open",
        })}
        primaryButton={
          <NavLink to={"/account"} tabIndex={-1}>
            <Button
              label={intl.formatMessage({
                id: "globalMenu.userWidget.actions.manageAccount",
              })}
              id="globalMenuAccountManagementButton"
              onClick={() => null}
              configuration={"primary"}
            />
          </NavLink>
        }
        secondaryButton={
          <NavLink
            to={keycloak.createLogoutUrl({
              redirectUri: window.location.origin + "/login",
            })}
            className="app__logout"
          >
            {intl.formatMessage({
              id: "globalMenu.userWidget.actions.logout",
            })}
          </NavLink>
        }
        toggleNameTooltipMessage={intl.formatMessage({
          id: "globalMenu.userWidget.actions.tooltipToggleName",
        })}
        toggleEmailTooltipMessage={intl.formatMessage({
          id: "globalMenu.userWidget.actions.tooltipToggleMail",
        })}
        portalOrigin={location.origin}
        imprintLabel={intl.formatMessage({
          id: "globalMenu.userWidget.actions.imprint",
        })}
        privacyPolicyLabel={intl.formatMessage({
          id: "globalMenu.userWidget.actions.privacy",
        })}
      />
      <main id="main" className="app__main">
        <div className="app__content">
          <ModalProvider>
            <Outlet context={{applications}} />
          </ModalProvider>
        </div>
      </main>
    </div>
  );
}

export default App;
