import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import "./index.css";
import App from "./App";
import English from "./lang/en.json";
import German from "./lang/de.json";
import ScreenSizeProvider from "./utility/ScreenSize";
import {PortalStyling} from "@spg/component_library/portal";
import {IntlProvider} from "react-intl";
import KeycloakProvider from "./utility/KeycloakProvider";
import {EnrichedSnackbarProvider} from "./utility/SnackbarProvider";
import Invitations from "./pages/Invitations/Invitations";
import Account from "./pages/Account/Account";
import Home from "./pages/Home/Home";
import Imprint from "./pages/unprotectedPages/Imprint";
import {ModalProvider, SnackbarProvider} from "@spg/component_library";
import Terms from "./pages/unprotectedPages/Terms/Terms";

const locale = navigator.language;
let lang = English as Record<string, string>;
if (locale.split("-")[0].toLowerCase() === "de") {
  lang = German as Record<string, string>;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/invitations",
        element: <Invitations />,
      },
    ],
  },
  {
    path: "/imprint",
    element: <Imprint />,
  },
  {
    path: "/terms",
    element: (
      <ModalProvider>
        <Terms />
      </ModalProvider>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <KeycloakProvider>
    <React.StrictMode>
      <IntlProvider locale={locale} messages={lang}>
        <ScreenSizeProvider>
          <SnackbarProvider>
            <EnrichedSnackbarProvider>
              <>
                <PortalStyling />
                <RouterProvider router={router} />
              </>
            </EnrichedSnackbarProvider>
          </SnackbarProvider>
        </ScreenSizeProvider>
      </IntlProvider>
    </React.StrictMode>
  </KeycloakProvider>,
);
