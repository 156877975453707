import React, {useContext, useState} from "react";
import "./Account.css";
import {Button, Icon} from "@spg/component_library";
import {FormattedMessage, useIntl} from "react-intl";
import API from "../../logic/API";
import {SnackbarContext} from "../../utility/SnackbarProvider";
import {Link} from "react-router-dom";
import chevron from "../../resources/images/chevron.svg";
import {KeycloakContext} from "../../utility/KeycloakProvider";
import {NavLink} from "react-router-dom";

/**
 * A page for account management
 */
function Account() {
  const {addSnackbar} = useContext(SnackbarContext);
  const intl = useIntl();
  const {keycloak} = useContext(KeycloakContext);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  return (
    <div className="account page__grid">
      <h1 className="account__title" id="accountTitle">
        <Link to="/" className="account__back">
          <Icon
            href={chevron + "#chevronLeft"}
            title={intl.formatMessage({id: "account.actions.back"})}
            strokeColor="#101223"
          />
        </Link>
        <FormattedMessage id="account.titles.page" />
      </h1>

      <div className="account__section">
        <h2 className="account__subtitle">
          <FormattedMessage id="account.titles.accountManagement" />
        </h2>
        <p>
          <FormattedMessage id="account.paragraphs.accountManagement" />
        </p>
        <p>
          <FormattedMessage id="account.paragraphs.deleteAccountWarning" />
        </p>
        <NavLink
          to={keycloak.createAccountUrl({
            redirectUri: window.location.origin + "/",
          })}
          tabIndex={-1}
        >
          <Button
            label={intl.formatMessage({
              id: "account.actions.manageAccount",
            })}
            id="accountEditPersonalInfoButton"
            onClick={() => null}
            configuration={"primary"}
          />
        </NavLink>
      </div>

      <div className="account__section">
        <h2 className="account__subtitle">
          <FormattedMessage id="account.titles.exportData" />
        </h2>
        <p>
          <FormattedMessage id="account.paragraphs.exportData" />
        </p>
        <Button
          label={intl.formatMessage({id: "account.actions.exportData"})}
          configuration={"primary"}
          disabled={waitingForResponse}
          id="accountExportDataButton"
          onClick={() => {
            setWaitingForResponse(true);
            API.exportGDPR().then((response) => {
              setWaitingForResponse(false);

              if (response.success) {
                addSnackbar({
                  variant: "timeout",
                  text: intl.formatMessage({
                    id: "account.messages.exportSucceeded",
                  }),
                  timeout: 7000,
                });
              } else
                addSnackbar({
                  variant: "close",
                  text: intl.formatMessage({
                    id: "account.messages.exportFailed",
                  }),
                  hasCloseButton: true,
                });
            });
          }}
        />
      </div>
    </div>
  );
}

export default Account;
